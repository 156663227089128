@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  @apply text-text bg-neutral-900;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

p {
  font-size: 1.1rem;
  @apply mb-4;
}


ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: inherit;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(120, 120, 120, .30);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(126, 126, 126, 0.7);
}

/* TODO: remove this after modals are in TW */

.forge-modal .modal-content {
  @apply bg-neutral-900;
}

.forge-modal .modal-header {
  @apply border-b border-b-neutral-800;
}

.forge-modal .modal-footer {
  @apply border-t border-t-neutral-800;
}

.forge-modal .btn-close {
  color: #eee !important;
}

ul {
  margin-left: 20px;
}

li {
  list-style: disc;
}